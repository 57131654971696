import { serverItemsToProGallery } from '@wix/pro-gallery-items-formatter';
import gogApi from './gogApi';
import defaultAlbumsService from './defaultAlbumsService';
import uuid from 'uuid';

const convertCoverToGalleryFormat = (cover: GogPhoto) => {
  const isSecuredCover = cover.metadata && cover.metadata.isSecure;
  if (cover.metadata?.type === 'video') {
    cover.url = cover.metadata.posters[0].url;
  }
  return {
    id: cover.photoId,
    dataType: 'Photo',
    name: cover.url,
    mediaUrl: `https://static.wixstatic.com/media/${cover.url}`,
    photoMetadata: cover.metadata
      ? {
          height: cover.metadata.height,
          width: cover.metadata.width,
        }
      : { height: 300, width: 300 },
    isSecure: isSecuredCover,
    imageToken: isSecuredCover && (cover.imageToken || cover.token),
  };
};

export const parseAlbumsCovers = (albums: GogAlbum[]) => {
  let albumsCovers: GalleryItem[] = [];
  const coverIdToAlbum: { [id: string]: GogAlbum } = {};
  const coverPhotosForGallery: GogPhotoForGallery[] = [];
  albums.forEach((album: any) => {
    try {
      let coverPhoto;
      const oldCover = album.sets[0].photos[0];
      if (oldCover) {
        coverPhoto = convertCoverToGalleryFormat(oldCover);
      } else {
        coverPhoto = {
          id: uuid(),
          dataType: 'Photo',
          name: 'https://static.wixstatic.com/media/60cca8_c6fad025c40a4acb840f458b3b6f5f5b~mv2.png',
          mediaUrl: `https://static.wixstatic.com/media/60cca8_c6fad025c40a4acb840f458b3b6f5f5b~mv2.png`,
          photoMetadata: { height: 300, width: 300 },
          isSecure: false,
        };
      }
      if (coverPhoto.id) {
        coverPhotosForGallery.push(coverPhoto);
        coverIdToAlbum[coverPhoto.id] = album;
      }
    } catch (e: any) {
      console.log('Failed to convert album cover of album ' + album.id);
    }
  });

  albumsCovers = serverItemsToProGallery(coverPhotosForGallery);
  albumsCovers.forEach((item: any, idx: number) => {
    try {
      const watermark = albums[idx].settings.watermark;
      item.metaData.title = albums[idx].editables.title;
      item.metaData.description = albums[idx].editables.date;
      item.orderIndex = albums[idx].orderIndex;
      if (watermark && coverPhotosForGallery[idx].isSecure) {
        item.metaData.watermark = watermark;
      }
    } catch (e: any) {
      try {
        console.log('Failed to parse album data ' + albums[idx].albumId);
      } catch (err) {}
    }
  });
  return { albumsCovers, coverIdToAlbum };
};

const gogHelper = (httpClient: any) => {
  const { getGogAlbums, getPublishedAlbums } = gogApi(httpClient);
  const { getDefaultAlbums, getTemplateDefaultAlbums } =
    defaultAlbumsService(httpClient);

  const getGogDataAndCovers = async (
    instanceId: string,
    instance: string,
    compId: string,
    defaultAlbumsIds: string[],
    isNewDefaultAlbums: boolean,
  ) => {
    const gogData = await getGogAlbums(instanceId, instance, compId);
    if (!gogData.albums || gogData.albums.length === 0) {
      if (defaultAlbumsIds && defaultAlbumsIds.length > 0) {
        gogData.albums = await getTemplateDefaultAlbums(
          defaultAlbumsIds,
          instance,
          isNewDefaultAlbums,
        );
      } else {
        gogData.albums = await getDefaultAlbums(isNewDefaultAlbums);
      }
    }

    const { albumsCovers, coverIdToAlbum } = parseAlbumsCovers(gogData.albums);
    return { gogData, albumsCovers, coverIdToAlbum };
  };

  const getAlbumsForDisplaySettings = async (
    instanceId: string,
    instance: string,
    compId: string,
  ) => {
    const requestGogData = getGogAlbums(instanceId, instance, compId);
    const requestPublishedAlbums = getPublishedAlbums(instance, instanceId);
    const [{ albums: gogAlbums }, { numFilteredAlbums }] = await Promise.all([
      requestGogData,
      requestPublishedAlbums,
    ]);
    return { gogAlbums, hasPublishedAlbums: !!numFilteredAlbums };
  };

  const getDefaultAlbumCovers = async (isNewDefaultAlbums: boolean) => {
    try {
      const albums = await getDefaultAlbums(isNewDefaultAlbums);
      const { albumsCovers } = parseAlbumsCovers(albums);
      return albumsCovers;
    } catch (e: any) {
      console.error(`Failed @getDefaultAlbumCovers : ${e}`);
    }
  };

  const getAdiData = async (
    instance: string,
    instanceId: string,
    compId: string,
  ) => {
    const isNewDefaultAlbums = true;

    const [{ albums: allPublishedAlbums }, { gogData }] = await Promise.all([
      getPublishedAlbums(instance, instanceId),
      getGogDataAndCovers(instanceId, instance, compId, [], isNewDefaultAlbums),
    ]);
    const gogAlbums = gogData.albums;
    if (allPublishedAlbums && allPublishedAlbums.length !== 0) {
      allPublishedAlbums.forEach((album: any) => {
        album.visible = gogAlbums.some(
          (gogAlbum: any) => gogAlbum.albumId === album.albumId,
        );
      });
    }

    return { allPublishedAlbums, gogAlbums };
  };

  return {
    getGogDataAndCovers,
    getDefaultAlbumCovers,
    getAdiData,
    getAlbumsForDisplaySettings,
  };
};

export default gogHelper;
